<template>
    <v-container id="login-container" >

        <v-row justify="center">
            <v-col cols="12" md="6">
                
                    <v-card >
                        
                        <v-card-title>
                            Login With your Google Account
                        </v-card-title>

                        <v-card-actions>
                            <v-btn @click="userStore.googleLogin">Login</v-btn>
                        </v-card-actions>

                    </v-card>

            </v-col>
        </v-row>

    </v-container>
</template>

<script setup>

import router from '../routes/index'
import { useUserStore } from '../stores/user';
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { auth } from '../firebase/index.js';


//access userStore
const userStore = useUserStore();

//function to log user in
// const login = async () => {

//     console.log('GoogleLogin.vue login() function ran');

//     //create auth provider
//     const provider = new GoogleAuthProvider();
    

//     try {

//         const result = await signInWithPopup(auth, provider);
//         const user = result.user;
//         console.log(user);

//     } catch (err) {
//         console.log(err);
//     }
    
// };

</script>