<template>
  <!-- all vuetify components need to be wrapped inside v-app -->
  <!-- navbar should be inside v-main component to avoid overlapping other router views -->
  <v-app class="bg-background">
    <v-main>
      <Navbar/>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script setup>
  import Navbar from './components/Navbar.vue';
  import { onAuthStateChanged } from '@firebase/auth';
  import { useUserStore } from './stores/user';
  import {auth} from './firebase/index.js';

  const userStore = useUserStore();

  onAuthStateChanged(auth, (user) => {
    userStore.fetchUser(user);
  });
</script>