//file for storing validation rule arrays used in forms

export const validationRules = {
    
    notEmpty: [
        //
        input => !!input || 'Field cannot be empty.',
    ],

    isURL: [
        //url must start with http:// or https:// or field must be empty
        input => {
            if (input === null || input === "") {
                return true;
            }

            const regex = new RegExp(/^(https?:\/\/)/i);
            if (regex.test(input)) {
                return true;
            } else {
                return 'URL must start with http:// or https://'
            }
        }
    ],

    isEmail: [
        //email must follow xxx@xxx.xxx format
        input => {
            const regex = new RegExp(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/i);
            if (regex.test(input)) {
                return true;
            } else {
                return 'Please enter a valid email address.'
            }
        }
    ],

    isPassword: [
        //must be at least 8 characters
        input => {
            if (input && input.length >= 8) {
                return true;
            } else {
                return 'Password must be at least 8 characters.'
            }
        }
    ],

    isNumber: [
        //input must be a whole number integer
        input => {
            const regex = new RegExp(/[0-9]+/)
            if (input && regex.test(input)) {
                return true;
            } else {
                return 'Value must be a whole number'
            }
        }
    ],

    isZipCode: [
        //input must be a valid 5 digit zip code
        input => {
            const regex = new RegExp(/^\d{5}$/);
            if (input && regex.test(input)) {
                return true;
            } else {
                return 'Please enter a valid 5-digit Zip Code'
            }
        }
    ],

    isPhoneNumber: [
        //input must match xxx-xxx-xxxx or (xxx) xxx-xxxx
        input => {
            const regex = new RegExp(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/);
            if (input && regex.test(input)) {
                return true;
            } else {
                return 'Please enter a valid phone number'
            }
        }
    ]
}