<template>
    <Loading v-if="salesRepStore.fetchState !== 'resolved' || tradeShowFormStore.fetchState !== 'resolved'"/>
    <div v-if="salesRepStore.fetchState === 'resolved' && tradeShowFormStore.fetchState === 'resolved'">
        <TradeShowButtons v-if="!tradeShowFormStore.selected"/>
        <TradeShowForm v-if="tradeShowFormStore.selected"/>
    </div>   
</template>

<script setup>
import TradeShowButtons from '../components/TradeShowButtons.vue';
import TradeShowForm from '../components/TradeShowForm.vue';
import Loading from '../components/Loading.vue';
import { useSalesRepStore } from '../stores/salesRep';
import { useTradeShowFormStore } from '../stores/tradeShowForm';
import { onMounted } from 'vue';

const salesRepStore = useSalesRepStore();

const tradeShowFormStore = useTradeShowFormStore();

onMounted(() => {
    salesRepStore.fetchSalesReps();
    tradeShowFormStore.getTradeShows();
})


</script>