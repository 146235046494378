<template>

<v-container>
    <v-form>
        <div v-if="solarEstimateStore.showForm === true">
            <!-- input fields card ------------------------------------------------------------------- -->
        <v-card class="pa-5 my-2">
            <v-card-title>Solar</v-card-title>
            <v-card-text class="mt-2">

                <v-text-field
                    label="System Size"
                    v-model="solarEstimateStore.systemSize"
                    required
                    :rules="validationRules.notEmpty"
                    suffix="kW"
                    color="primary"
                    variant="outlined"
                ></v-text-field>

                <v-text-field
                    label="Solar Labor Rate"
                    v-model="solarEstimateStore.solarLaborRate"
                    required
                    :rules="validationRules.notEmpty"
                    prefix="$"
                    suffix="per SQ"
                    color="primary"
                    variant="outlined"
                ></v-text-field>

            </v-card-text>
        </v-card>

        <v-card class="pa-5 my-2">
            <v-card-title>Roofing</v-card-title>

            <v-card-text class="mt-2">

                <v-text-field
                    label="Roof Size"
                    v-model="solarEstimateStore.roofSize"
                    required
                    :rules="validationRules.notEmpty"
                    suffix="SQ"
                    color="primary"
                    variant="outlined"
                ></v-text-field>

                <v-text-field
                    label="Roof Labor Rate"
                    v-model="solarEstimateStore.roofLaborRate"
                    required
                    :rules="validationRules.notEmpty"
                    prefix="$"
                    suffix="per SQ"
                    color="primary"
                    variant="outlined"
                ></v-text-field>

                <v-text-field
                    label="Accessories"
                    v-model="solarEstimateStore.accessories"
                    prefix="$"
                    color="primary"
                    variant="outlined"
                ></v-text-field>


            </v-card-text>
        </v-card>

        <!-- Adders Card ----------------------------------------------------- -->
        <v-card class="pa-5 my-2">

            <v-card-title>Adders</v-card-title>

            <v-card-text>

                <v-row>
                    <v-col cols="8" md="3">
                        <v-switch
                            label="Zone 2 Adder"
                            color="primary"
                            v-model="solarEstimateStore.zone2Adder"
                        >
                        </v-switch>
                    </v-col>
                    
                    <v-col cols="4" class="pt-6">
                        <v-btn 
                                    icon="mdi-information"
                                    color="primary"
                                    density="compact"
                                    variant="text"
                                    @click="tooltipControl.showZone2Adder = !tooltipControl.showZone2Adder"
                                    
                            ></v-btn>
                    </v-col>
                
                </v-row>
                    
                <v-row>
                    <v-col cols="8" md="3">
                        <v-switch
                            label="Multi-Array Adder"
                            color="primary"
                            v-model="solarEstimateStore.multiArrayAdder"
                        ></v-switch>
                    </v-col>

                    <v-col cols="4" class="pt-6">
                            <v-btn 
                                    icon="mdi-information"
                                    color="primary"
                                    density="compact"
                                    variant="text"
                                    @click="tooltipControl.showMultiArrayAdder = !tooltipControl.showMultiArrayAdder"
                                    
                            ></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </div>

        <!-- Margins card ----------------------------------------------------- -->
        <v-card class="pa-5 my-2" v-if="solarEstimateStore.showMargins">
            <v-card-title>Margins</v-card-title>
            <v-card-text class="mt-2">

            <div class="my-5">
                <div class="text-subtitle-1 text-center">Solar Margin</div>
                <div class="text-h6 text-center text-primary">{{ solarMarginPercentage }}%</div>
                <v-slider
                    v-model="solarEstimateStore.solarMargin"
                    color="primary"
                    :step="0.01"
                    :max="1.00"
                    :min="0.00"
                >
                    <template v-slot:prepend>
                        <v-btn
                            size="small"
                            variant="text"
                            icon="mdi-minus"
                            color="primary"
                            @click="decrementSolar"
                        ></v-btn>
                    </template>

                    <template v-slot:append>
                        <v-btn
                            size="small"
                            variant="text"
                            icon="mdi-plus"
                            color="primary"
                            @click="incrementSolar"
                        ></v-btn>
                    </template>



                </v-slider>
                <div class="text-h6 text-center text-primary">Price Per Watt $ {{ pricePerWatt }}</div>
            </div>

            <div class="my-5">
                <div class="text-subtitle-1 text-center">Roof Margin</div>
                <div class="text-h6 text-center text-primary">{{ roofMarginPercentage }}%</div>
                <v-slider
                    v-model="solarEstimateStore.roofMargin"
                    color="primary"
                    :step="0.01"
                    :max="1.00"
                    :min="0.00"
                >
                    <template v-slot:prepend>
                        <v-btn
                            size="small"
                            variant="text"
                            icon="mdi-minus"
                            color="primary"
                            @click="decrementRoof"
                        ></v-btn>
                    </template>

                    <template v-slot:append>
                        <v-btn
                            size="small"
                            variant="text"
                            icon="mdi-plus"
                            color="primary"
                            @click="incrementRoof"
                        ></v-btn>
                    </template>

                </v-slider>
                <div class="text-h6 text-center text-primary">Price Per SQ $ {{ pricePerSQ }}</div>
            </div>
                

            </v-card-text>
        </v-card>

    </v-form>

    <!-- ------------------------ Modals for Tooltips ------------------------ -->

    <v-dialog
                    v-model="tooltipControl.showMultiArrayAdder"
                    width="auto"
                >
                    <v-card class="pa-5">
                        <v-card-title class="text-primary text-center">Multi-Array Adder</v-card-title>
                        <div class="divider"></div>
                        <v-card-text>
                            Select Multi-Array Adder if the roof will have more than 1 Solar Array.
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="tooltipControl.showZone2Adder"
                    width="auto"
                >
                    <v-card class="pa-5">
                        <v-card-title class="text-primary text-center">Zone 2 Adder</v-card-title>
                        <div class="divider"></div>
                        <v-card-text>
                            Select Zone 2 Adder if the service address is outside of GAF's service area.
                        </v-card-text>
                    </v-card>
                </v-dialog>
</v-container>
   
    
</template>

<script setup>
import { computed, reactive } from 'vue';
import { validationRules } from '../utils/validators';
import { useSolarEstimateStore } from '../stores/solarEstimate';
import { roundToHundredths } from '../utils/mathFunctions';

const solarEstimateStore = useSolarEstimateStore();


// SLIDERS -------------------------------------------------------------------

//computed values to display slider percentages ------------------------------

const solarMarginPercentage = computed(() => {
    //use .toFixed() to prevent issues with Floats showing extra decimals
    return (solarEstimateStore.solarMargin * 100).toFixed(0);
});

const roofMarginPercentage = computed(() => {
    //use .toFixed() to prevent issues with Floats showing extra decimals
    return (solarEstimateStore.roofMargin * 100).toFixed(0);
})

//computed values for Price Per Watt and Price Per Square

const pricePerWatt = computed(() => {

    const solarPrice = Math.round(solarEstimateStore.estimateData.data.totalSolarCost * (1 / (1 - solarEstimateStore.solarMargin)));

    return roundToHundredths(solarPrice / (solarEstimateStore.systemSize * 1000));
})

const pricePerSQ = computed(() => {
    const roofPrice = Math.round(solarEstimateStore.estimateData.data.totalRoofCost * (1 / (1 - solarEstimateStore.roofMargin)));

    return roundToHundredths( roofPrice / solarEstimateStore.estimateData.data.roofOnlySize);
})

//increment and decrement functions for the margin sliders ------------------------------

const incrementSolar = () => {
    solarEstimateStore.solarMargin+=0.01;
}

const decrementSolar = () => {
    solarEstimateStore.solarMargin-=0.01;
}

const incrementRoof = () => {
    solarEstimateStore.roofMargin+=0.01;
}

const decrementRoof = () => {
    solarEstimateStore.roofMargin-=0.01;
}

// TOOLTIPS FOR SWITCHES -----------------------------------------------------

const tooltipControl = reactive({
    showZone2Adder: false,
    showMultiArrayAdder: false,
});


</script>