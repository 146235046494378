<template>

        <v-card class="bg-primary">
            <v-card-text>
                <v-row class="mb-2">
                    <v-col class="font-weight-medium">
                        Your Request will be sent to the following manager for approval:
                    </v-col>
                </v-row>
                
                    <v-card>
                        <v-row class="pa-5">
                        <v-col cols="12" md="6">
                                <div>
                                    {{ officeStore.selectedOffice.regionalManagerName }}
                                </div>
                                <div>
                                    <a href="" class="text-primary">{{ officeStore.selectedOffice.regionalManagerEmail }}</a>
                                </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div>
                                {{ officeStore.selectedOffice.street }}
                            </div>
                            <div>
                                {{officeStore.selectedOffice.city}}, {{ officeStore.selectedOffice.state }} {{ officeStore.selectedOffice.zip }}
                            </div>
                        </v-col>
                        </v-row>
                    </v-card>
           
                
            </v-card-text>

        </v-card>

</template>

<script setup>

    import { useOfficeStore } from '../stores/office';

    const officeStore = useOfficeStore();

</script>