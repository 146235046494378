<template>
    <div class="text-center my-2">
            <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
        </div>
        <div class="text-center text-h5 my-2">
            Loading
        </div>
</template>

<script setup>

</script>