//theme object for Vuetify, using AOCG brand colors
// import this file into the file where createVuetify() function is called

//inside createVueity() function, add this property to the object being passed in

// theme: {
//     defaultTheme: 'aocgTheme',
//     themes: {
//       aocgTheme: aocgTheme
//       }
//     }

const aocgTheme = {
    dark: true,
    colors: {
        primary: "#D7263D",
        secondary: "#FBFEF9",
        info: "#0E79B2",
        warning: "#F39237",
        background: "#02182B",
        surface: "#FBFEF9",
        lightGray: "#E0E0E0"
    }
}

export default aocgTheme;