<template>
    <v-container>
        <v-card class="pa-5">
            <v-card-title>Hardware Request Form</v-card-title>
            <v-card-text>
                <v-form ref="hardwareForm">                    

                    <v-row>
                        <v-col cols="12" md="6">

                            <v-text-field
                                label="Requested For"
                                placeholder="Enter the employee's full name."
                                v-model="formData.Requested_For"
                                required
                                :rules="validationRules.notEmpty"
                            ></v-text-field>

                            <v-select
                                label="Hardware Type"
                                :items="hardwareTypes"
                                v-model="formData.Hardware_Type"
                                required
                                :rules="validationRules.notEmpty"
                            ></v-select>

                        </v-col>
                        <v-col cols="12" md="6">

                            <v-text-field
                                label="User Email"
                                placeholder="Enter the employee's email address."
                                v-model="formData.Email"
                                required
                                :rules="validationRules.isEmail"
                            ></v-text-field>

                            <v-select
                                label="Office"
                                :items="sortedOffices"
                                v-model="formData.Office"
                                item-title="name"
                                item-value="name"
                                @update:modelValue="updateSelectedOffice"
                                required
                                :rules="validationRules.notEmpty"
                            ></v-select>
                            
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12">
                            <!-- only display if an office is selected -->
                            <ApproverPreview 
                                v-if:="officeStore.selectedOffice"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-textarea
                                label="Reason for Request"
                                placeholder="Please enter a business reason or justification for your request."
                                required
                                :rules="validationRules.notEmpty"
                                v-model="formData.Reason"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    

                </v-form>
            </v-card-text>

            <v-card-actions class="justify-center">

                <v-btn color="primary" variant="elevated" @click="submitHandler">Submit</v-btn>
                <v-btn color="warning" variant="elevated" @click="clearFormHandler">Clear</v-btn>

            </v-card-actions>

        </v-card>

         <!-- Sending Form Modal -->
            <v-dialog
                v-model="data.isSending"
                :scrim="false"
                persistent
                class="w-50"
                min-width="200"
                max-width="400"
            >
                <v-card color="primary" class="pa-5">
                    <v-card-text>
                        Submitting
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        >
                        </v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- Form Submitted Successfully Modal -->
            <v-dialog
                v-model="data.isSuccess"
                :scrim="false"
                persistent
                class="w-50"
                min-width="200"
                max-width="400"
            >
                <v-card color="success" class="pa-5">
                    <v-card-text class="text-center">
                        Successfully Submitted!
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn @click="data.isSuccess = !data.isSuccess">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Email Error modal -->
            <v-dialog
                v-model="data.isError"
                :scrim="false"
                persistent
                class="w-50"
                min-width="200"
            >
                <v-card color="error">
                    <v-card-text class="text-center">
                        <div class="my-2">
                            There was a problem submitting the form:
                        </div>
                        <div class="my-2">
                            {{ (data.error).toUpperCase() }}
                        </div>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn @click="data.isError = !data.isError">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script setup>

    import { useOfficeStore } from '../stores/office';
    import {useUserStore} from '../stores/user';
    import { reactive, ref, computed } from 'vue';
    import { validationRules } from '../utils/validators';
    import ApproverPreview from './ApproverPreview.vue';

    //import  to call cloud functions
    import { useInvokeCF } from '../composables/invokeCF.js';

    //construct createHardwareRequest function object
    const createHardwareRequest = useInvokeCF('createHardwareRequestCF');

    const officeStore = useOfficeStore();
    const userStore = useUserStore();

    //sorts the offices alphabetically before displaying them in the dropdown
    const sortedOffices = computed(() => {
        return officeStore.offices.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (b.name < a.name) {
                return 1;
            }
            return 0;
        });
    });

    //reactive state data of component
    const data = reactive({
        fetchState: 'standby',
        error: null,
        isSending: false,
        isSuccess: false,
        isError: false,
        response: null,
    })

    //create ref to hold hardware form submission
    const hardwareForm = ref(null);

    const hardwareTypes = [
        'PC',
        'Laptop',
        'Monitor',
        'Cell Phone',
        'Tablet',
        'Laptop Dock',
        'Printer',
        'Other',
    ]

    //function that returns a fresh set of default values to be assigned to the formData reactive object, whenever the page loads or the clearForm function is called
    //Email = email of user that the request is being submitted for
    const defaultValues = () => ({
        Email: null,
        Hardware_Type: null,
        Office: null,
        Reason: null,
        Requested_By: null,
        Requested_For: null,
        Requestor_Email: null,
        Request_Status: "Pending",
        Approver: null,
        Approver_Email: null,
    });

    //reactive state data of form
    //variable names match names of Zoho API fields
    const formData = reactive(defaultValues())  
    
    //updates the officeStore with the selected office
    //triggered when user selects an office from the dropdown
   const updateSelectedOffice = () => {

        //filters the offices array to find the office that matches the selected office
        const selectedOffice = officeStore.offices.filter(office => office.name === formData.Office)[0];

        //update the officeStore with the selected office
        officeStore.setOffice(selectedOffice);
   }

   //form submission handler
   const submitHandler = async () => {

        console.log(userStore.profile);
    
        //validate the form
        const { valid }= await hardwareForm.value.validate();

        //if the form is valid, submit the form
        if (valid) {
            //construct payload
            const payload = { ...formData };

            //append zoho-specific properties to the payload
            payload.Requested_By = userStore.profile.name;
            payload.Requestor_Email = userStore.profile.email;
            payload.Approver = officeStore.getOfficeData.regionalManagerName;
            payload.Approver_Email = officeStore.getOfficeData.regionalManagerEmail;

            //use createHardwareRequest CF object to invoke cloud function
            data.fetchState = 'pending';
            data.isSending = true;
            data.response = await createHardwareRequest.invokeCF(payload);

            if (data.response.data.data.length > 0) {

                const zohoResponse = data.response.data.data[0];
                data.isSending = false;

                if (zohoResponse.code === 'SUCCESS') {
                    data.fetchState = 'resolved';
                    data.isSuccess = true;
                    clearFormHandler();
                } else {
                    data.fetchState = 'rejected';
                    data.isError = true;
                    data.error = zohoResponse.message
                    console.log('Error Response from Zoho: ',zohoResponse);
                }

                } else {
                data.fetchState = 'rejected';
                data.isError = true;
                data.error = zohoResponse.message
                console.log('Error Response from Zoho: ',zohoResponse);
            }

        }

   }

   //form clear handler
   const clearFormHandler = () => {
        //reset selected office
        officeStore.clearOffice();

        //reset the form data
        Object.assign(formData, defaultValues());
   }

</script>