// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// Auth functionality
import { getAuth, connectAuthEmulator } from 'firebase/auth';
// App Check
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
//firebase functions
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
// firebase functionality
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

//set function host url to production url
let FUNCTIONHOST = `https://us-central1-zoho-portal-255fd.cloudfunctions.net/`;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcev74kiyXBda8zUygBqhlk0UtvIvdeM0",
  authDomain: "zoho-portal-255fd.firebaseapp.com",
  projectId: "zoho-portal-255fd",
  storageBucket: "zoho-portal-255fd.appspot.com",
  messagingSenderId: "41824384368",
  appId: "1:41824384368:web:ea35426efdb723120acbb5",
  measurementId: "G-KMBFXDHG6V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//use Debug mode for App Check for local testing
//open the browser while emulating the app and get the debug token, then register it on the App Check section of your App on firebase.google.com
if (process.env.NODE_ENV === 'development') {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }

//configures Firebase App Check using site key created by ReCaptchaV3 and checks it against ReCaptcha secret stored on firebase backend
// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider('TODO: ADD RECAPTCHA CODE'),
//     isTokenAutoRefreshEnabled: true
//   });

//get all cloud functions in Firebase so they can be referenced in other components
const functions = getFunctions(app);

//gets auth object that can be referenced in other components
const auth = getAuth(app);

//gets firestore database object that can be referenced in other components
const db = getFirestore(app);

// const analytics = getAnalytics(app);

//if in dev mode
if (process.env.NODE_ENV === 'development') {
  //set function host URL to local emulator
  FUNCTIONHOST = 'http://localhost:5001/zoho-portal-255fd/us-central1'

    //use auth emulator for local testing
  connectAuthEmulator(auth, "http://localhost:9099");
  
  //use functions emulator for local testing
  connectFunctionsEmulator(functions, "localhost", 5001);

  //use the firestore database emulator for local testing
  //firestore emulator requires java runtime and java added to PATH
  connectFirestoreEmulator(db, 'localhost', 8081);
  }
  

export {auth, functions, db, app as firebaseApp};
export { FUNCTIONHOST };