// creates a store that stores data captured by the form and calculated by the
// solar estimate cloud function
import { defineStore } from 'pinia';

//import invokeCF to call cloud functions
import { useInvokeCF } from '../composables/invokeCF.js';

const calculateSolarEstimateCF = useInvokeCF('calculateSolarEstimateCF');

export const useSolarEstimateStore = defineStore({
    id: 'solarEstimate',
    state: () => ({
        showForm: true,
        showMargins: false,
        showEstimate: false,
        showLeadForm: false,
        showError: false,
        fetchState: 'standby',
        error: null,
        estimateData: null,
        zone2Adder: false,
        multiArrayAdder: false,
        systemSize: 7,
        solarLaborRate: 150,
        roofSize: 30,
        roofLaborRate: 75,
        accessories: 0,
        solarMargin: 0.1,
        roofMargin: 0.4
    }),
    getters: {
        getEstimateData: (state) => {
            if (state.estimateData !== null) {
                return state.estimateData.data;
            } else {
                return null;
            }
        },

        getCurrentInput: (state) => {
            
            return {
                zone2Adder: state.zone2Adder,
                multiArrayAdder:  state.multiArrayAdder,
                systemSize: state.systemSize,
                solarLaborRate: state.solarLaborRate,
                roofSize: state.roofSize,
                roofLaborRate: state.roofLaborRate,
                accessories: state.accessories,
                solarMargin: state.solarMargin,
                roofMargin: state.roofMargin,
            };
        }
    },
    actions: {
        resetToDefault() {
            this.showForm = true;
            this.showEstimate = false;
            this.showLeadForm = false;
            this.showMargins = false;
            this.fetchState = 'standby';
            this.error = null;
            this.estimateData = null;
            this.zone2Adder = false;
            this.multiArrayAdder = false;
            this.systemSize = 7;
            this.solarLaborRate = 150;
            this.roofSize = 30;
            this.roofLaborRate = 75;
            this.accessories = 0;
            this.solarMargin = 0.1;
            this.roofMargin = 0.4;
        },

        async calculateEstimate(input) {
            this.fetchState = 'pending';
            try {
                this.estimateData = await calculateSolarEstimateCF.invokeCF(input);
                this.fetchState = 'resolved';
            } catch (err) {
                console.log('Error in calculateEstimate in solarEstimateStore: ', err);
                this.error = err;
                this.fetchState = 'rejected';
                this.showError = true;
            }
        },

        showEstimateHandler() {
            this.showEstimate = true;
            this.showForm = false;
            this.showMargins = false;
        },

        goBackToMarginsHandler() {
            this.showEstimate = false;
            this.showForm = false;
            this.showMargins = true;
        },

        goBackToInputsHandler() {
            this.showEstimate = false;
            this.showForm = true;
            this.showMargins = false;
        },

       

    }
})