import { db } from "./index";
import { collection, query, getDocs } from 'firebase/firestore';

//fetches list of all trade show records from the tradeShows collection
const getTradeShowsDb = async () => {

    try {
        //define query
        const tradeShowQuery = query(
            collection(db, 'tradeShows')
        );

        //get snapshot from database
        const querySnapshot = await getDocs(tradeShowQuery);

        const results = [];

        //for each document returned, get the data and add it to the results array
        querySnapshot.forEach((doc) => {
            results.push(doc.data());
        })

        return results;
    } catch (err) {
        console.log("Error with getTradeShowsDb in tradeShowDb.js: ", err);
        throw new Error(err.message);
    }
    
}

export { getTradeShowsDb };