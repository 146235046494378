import { db } from "./index";
import { collection, query, getDocs } from 'firebase/firestore';

//fetches list of all offices from the offices collection
const getOfficesDb = async () => {

    try {

        //define query
        const officeQuery = query(
            collection(db, 'offices')
        );

        // get snapshot from database
        const querySnapshot = await getDocs(officeQuery);

        const results = [];

        //for each document returned, get the data and add it to the results array
        querySnapshot.forEach((doc) => {
            results.push(doc.data());
        })

        return results;
    } catch(err) {
        console.log("Error with getOfficesDb in officeDb.js: ", err);
        throw new Error(err.message);
    }

}

export { getOfficesDb };