import { defineStore } from 'pinia';
import { useInvokeCF } from '../composables/invokeCF.js';

export const useSalesRepStore = defineStore({
    id: 'salesRep',
    state: () => ({
        fetchState: 'standby',
        data: null,
        response: null,
        getActiveUsers: useInvokeCF('getActiveUsersCF'),
        error: null,
    }),
    getters: {
        getSalesReps: (state) => {
            if (state.data !== null) {
                return state.data;
            } else {
                return [];
            }
        }
    },
    actions: {
        async fetchSalesReps() {
            this.fetchState = 'pending';

            try {
                //get response from Cloud Function
                this.response = await this.getActiveUsers.invokeCF();
                //Extract Data and save to state data
                this.data = this.response.data;
                //mark fetchState as resolved
                this.fetchState = 'resolved'
            } catch (err) {
                console.log('Error in fetchSalesRep in salesRep Store: ', err);
                this.error = err;
                this.fetchState = 'rejected'
            }
        
            
        }
    }
})