<template>
    <nav>
        <v-app-bar flat app color="primary">
            <v-app-bar-nav-icon icon="mdi-menu" @click.stop="data.drawer=!data.drawer"/>
            <v-app-bar-title>Zoho Portal</v-app-bar-title>
            <!-- Login button, only displays if user is not logged in -->
            <v-btn
                v-if="userStore.fetchState !== 'resolved'"
                variant="text"
                :href="'/login'"
                ><v-icon class="mr-1">mdi-login-variant</v-icon>Login</v-btn>
            <!-- Logout button, only displays if user is logged in -->
            <v-btn 
                v-if="userStore.fetchState === 'resolved'"
                variant="text"
                @click="logout"
                ><v-icon class="mr-1">mdi-logout-variant</v-icon>Logout</v-btn>
        </v-app-bar>

        <v-navigation-drawer v-model="data.drawer" app class="secondary">
            <v-list>
                <v-list-item v-for="link in data.links" key="link.text" router :to="link.route">
                    <v-list-item-title>
                        {{ link.text }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script setup>
    import {reactive} from 'vue'
    import { useUserStore } from '../stores/user';
    import {auth} from '../firebase/index.js';

    const userStore = useUserStore();

    const data = reactive({
        drawer: false,
        links: [
            {
                text: 'Home',
                route: '/'
            },
            {
                text: 'Trade Shows',
                route: '/tradeshows'
            },
            {
                text: 'Solar Estimate',
                route: '/solar'
            },
            {
                text: 'Hardware Requests',
                route: '/hardware-requests'
            }
        ]
    });

    const logout = async () => {
        await userStore.logout();
    }

</script>