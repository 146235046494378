<template>
    <v-container>
      <v-card class="pa-5">
    
          <div class="text-center text-h5">Solar Array and Roof</div>
          <div class="divider mb-5"></div>

          <v-card class="pa-3 my-3" color="background">
            <div class="text-center text-h3">
            $ {{ data.retailPrice }}
            </div>
            <div class="text-center text-h6">Estimated Total Cost</div>
          </v-card>

          <v-card class="pa-3 my-3" color="background">
            <div class="text-center text-h4">
            $ {{ data.solarPrice }}
            </div>
            <div class="text-center text-h6">Estimated Solar Array Cost</div>
          </v-card>

          <v-card class="pa-3 my-3" color="background">
            <div class="text-center text-h4">
            $ {{ data.roofPrice }}
            </div>
            <div class="text-center text-h6">Estimated Roof Cost</div>
          </v-card>
      
    </v-card>
    <v-spacer class="my-5"></v-spacer>
    <v-card class="pa-5">
    
    <div class="text-center text-h5">Roof Only</div>
    <div class="divider mb-5"></div>

    <v-card class="pa-3 my-3" color="background">
      <div class="text-center text-h3">
      $ {{ data.noSolarRoofPrice }}
      </div>
      <div class="text-center text-h6">Estimated Total Cost</div>
    </v-card>

</v-card>
    <!-- <div>{{ data }}</div> -->
    </v-container>
</template>

<script setup>
import { useSolarEstimateStore } from '../stores/solarEstimate';

const solarEstimateStore = useSolarEstimateStore();

const data = { ...solarEstimateStore.getEstimateData };

</script>