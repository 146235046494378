import { db } from "./index";
import {doc, getDoc, setDoc} from 'firebase/firestore';
 
 //fetches user Profile from the users collection in the firestore db
 export const fetchUserProfile = async (userId) => {

    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        
        return docSnap.data();
    } else {
        console.log('No Document Found with that ID')
        return null;
    }
    
}

//creates new user Profile in db
export const createNewUserProfile = async (userId, profile) => {

    console.log("User ID: ", userId);
    console.log("User Data: ", profile);

    try {
        const result = await setDoc(doc(db, 'users', userId), profile);
    }
    catch (err) {
        console.log(err);
    }

}