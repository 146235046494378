<template>
    <v-container id="home-container" class="w-75">

        

        <!-- <div id="test-container" v-if="userStore.profile && userStore.profile.admin">
            This is some text
        
            <v-btn @click="getActiveUsersHandler">Test Get Active Users</v-btn>
            <v-btn @click="createReRoofLeadHandler">Test Create ReRoof Lead</v-btn>
            
        </div> -->

        <header class="my-2">
            <div class="text-center text-h4 mt-4">ALPHA OMEGA</div>
            <div class="text-center text-h4">ZOHO PORTAL</div>
            <div class="divider"></div>
        </header>

        <section class="pa-5 text-center">
            <div class="text-h5 mb-2">
                Welcome to the Alpha Omega Zoho Portal!
            </div>
            <div>
                <p>This web app allows you to access information in Zoho CRM without a Zoho account.</p>
                <p>Click the Navigation Menu at the top left to get started.</p>
            </div>
            <div class="mt-4">
                <p class="text-h6 mb-2">You can use this app to:</p>
                <div class="d-flex justify-center">
                    <ul class="text-left">
                        <li>Create Leads at Trade Shows</li>
                        <li>Generate initial Solar Estimates for Customers</li>
                        <li>Submit Hardware Requests for Employees</li>
                    </ul>
                </div>
            </div>
        </section>
        

        
    </v-container>
</template>

<script setup>
    import { reactive } from 'vue';
    import { useUserStore } from '../stores/user';
    import { useInvokeCF } from '../composables/invokeCF.js';
    import { auth } from '../firebase/index';

    const userStore = useUserStore();

    auth.onAuthStateChanged( user => {
        userStore.fetchUser(user);
    });

    const getActiveUsers = useInvokeCF('getActiveUsersCF');

    const getActiveUsersHandler = async () => {
        const response = await getActiveUsers.invokeCF();
        console.log(response);
    }

    const createReRoofLead = useInvokeCF('createReRoofLeadCF');

    const createReRoofLeadHandler = async () => {
        const testData = {
                Last_Name: "Lead",
                First_Name: "API Test",
                Market: "Charlotte",
                Lead_Source: "Trade Show",
                Phone: "818-282-1313",
                Email: "testo2@besto1.com",
                Street: "100 Test Street",
                City: "Charlotte",
                State: "NC",
                Zip_Code: "12345",
                Owner: {
                    name: "Zoho Admin",
                    id: "4331172000000246013",
                    email: "zoho@alpha-omegainc.com"
                },
                Layout: {
                    name: "ReRoof Sales",
                    id: "4331172000000091055"
                },
                Department: "Reroof",
                Webform_Source: "Self Lead Form",
            }

        const response = await createReRoofLead.invokeCF(testData);
        console.log(response);
    }

</script>