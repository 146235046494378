<template>
    <v-container>
        <v-form ref="tradeShowForm">
            <v-row justify="center">
                <v-col cols="12" md="8">
                    <v-card class="pa-5">

                        <v-card-title class="mb-3">
                            <div class="text-h5 text-truncate">{{ tradeShowFormStore.getTradeShowName }}</div> 
                        </v-card-title>

                        <v-card-text>
                            <div id="customer-info-form" class="my-3">
                                                                
                                <!-- First Name Input -->
                                <v-text-field
                                    label="First Name"
                                    v-model="formData.First_Name"
                                    required
                                    :rules="validationRules.notEmpty"
                                ></v-text-field>

                                <!-- Last Name Input -->
                                <v-text-field
                                    label="Last Name"
                                    v-model="formData.Last_Name"
                                    required
                                    :rules="validationRules.notEmpty"
                                ></v-text-field>

                                <!-- Email Input -->
                                <v-text-field
                                    label="Email Address"
                                    v-model="formData.Email"
                                    required
                                    :rules="validationRules.isEmail"
                                    placeholder="yourname@domain.com"
                                ></v-text-field>

                                <!-- Phone Input -->
                                <v-text-field
                                    label="Phone Number"
                                    v-model="formData.Phone"
                                    type="tel"
                                    required
                                    :rules="validationRules.isPhoneNumber"
                                    placeholder="xxx-xxx-xxxx"
                                ></v-text-field>

                                <!-- Text Message Opt Out -->
                                <v-switch
                                    label="Do Not Send Me Text Messages"
                                    color="success"
                                    v-model="formData.Text_Message_Opt_Out"
                                ></v-switch>
                                
                            </div>
                            
                            <div class="my-3" id="contact-address-form">
                                <div class="px-1 mb-6 text-h6">Contact Address</div>

                                <!-- Street Input -->
                                <v-text-field
                                    label="Street"
                                    v-model="formData.Street"
                                    required
                                    :rules="validationRules.notEmpty"
                                ></v-text-field>

                                <!-- City Input -->
                                <v-text-field
                                    label="City"
                                    v-model="formData.City"
                                    required
                                    :rules="validationRules.notEmpty"
                                ></v-text-field>

                                <!-- State Select -->
                                <v-select
                                    label="State"
                                    v-model="formData.State"
                                    required
                                    :items="states"
                                ></v-select>

                                <!-- Zip Code Input -->
                                <v-text-field
                                    label="Zip Code"
                                    v-model="formData.Zip_Code"
                                    required
                                    :rules="validationRules.isZipCode"
                                ></v-text-field>
                            </div>

                            <div class="my-3" id="service-address-form">
                                <div class="px-1 mb-1 text-h6">Service Address</div>

                                <!-- Copy Contact Address to Job Site Address -->
                                <v-switch
                                    label="Same as Contact Address"
                                    color="success"
                                    v-model="data.copyAddress"
                                    @click="copyAddressHandler"
                                ></v-switch>

                                <!-- container for job site address inputs, only shown if data.copyAddress == false -->
                                <div v-if="!data.copyAddress">
                                    <!-- Service Street Input -->
                                    <v-text-field
                                        label="Street"
                                        v-model="formData.Job_Site_Street"
                                        required
                                        :rules="validationRules.notEmpty"
                                    ></v-text-field>

                                    <!-- Service City Input -->
                                    <v-text-field
                                        label="City"
                                        v-model="formData.Job_Site_City"
                                        required
                                        :rules="validationRules.notEmpty"
                                    ></v-text-field>

                                    <!-- State Select -->
                                    <v-select
                                        label="State"
                                        v-model="formData.Job_Site_State"
                                        required
                                        :items="states"
                                    ></v-select>

                                    <!-- Zip Code Input -->
                                    <v-text-field
                                        label="Zip Code"
                                        v-model="formData.Job_Site_Zip_Code"
                                        required
                                        :rules="validationRules.isZipCode"
                                    ></v-text-field>
                                    </div>
                            </div>

                            <div class="my-3" id="service-info-form">
                                <div class="px-1 mb-6 text-h6">Service Information</div>
                                
                                <!-- Service Type Input -->
                                <div class="px-1 mb-1">Please select all that apply</div>
                                <v-select
                                    label="Service Type"
                                    :items="serviceTypes"
                                    multiple
                                    chips
                                    v-model="formData.Service_Type_Select_All_That_Apply"
                                    required
                                ></v-select>

                                <!-- Storm Damage Input -->
                                <v-select
                                    label="Storm Damage Suspected?"
                                    :items="['Yes','No', 'Unsure', 'Not Applicable']"
                                    v-model="formData.Storm_Damage_Suspected"
                                    required
                                ></v-select>

                                <!-- Description Input -->
                                <v-textarea
                                    label="Description of Services Needed"
                                    v-model="formData.Description"
                                ></v-textarea>

                                <!-- Sales Rep Input -->
                                <v-select
                                    label="Sales Consultant"
                                    :items="salesReps"
                                    item-title="name"
                                    item-value="id"
                                    v-model="data.selectedOwner"
                                    required
                                ></v-select>

                            </div>

                        </v-card-text>

                        <v-card-actions class="d-flex justify-center flex-wrap px-4">
                            <!-- check screen size, if screen is smaller than md or larger, make button a block element -->
                            <v-btn :block="!mdAndUp ? true : false" variant="elevated" color="primary" @click="submitHandler" class="ma-2">Submit</v-btn>
                            <v-btn :block="!mdAndUp ? true : false" variant="elevated" color="warning" @click="clearForm" class="ma-2">Clear</v-btn>
                            <v-btn :block="!mdAndUp ? true : false" variant="elevated" color="warning"  @click="goBack" class="ma-2">Back</v-btn>
                        </v-card-actions>

                    </v-card>
                </v-col>
            </v-row>
        </v-form>

        <!-- Sending Form Modal -->
    <v-dialog
        v-model="data.isSending"
        :scrim="false"
        persistent
        class="w-50"
        min-width="200"
    >
        <v-card color="primary">
            <v-card-text>
                Submitting
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                >
                </v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- Form Submitted Successfully Modal -->
    <v-dialog
        v-model="data.isSuccess"
        :scrim="false"
        persistent
        class="w-50"
        min-width="200"
    >
        <v-card color="success">
            <v-card-text class="text-center">
                Successfully Submitted!
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="data.isSuccess = !data.isSuccess">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Email Error modal -->
    <v-dialog
        v-model="data.isError"
        :scrim="false"
        persistent
        class="w-50"
        min-width="200"
    >
        <v-card color="error">
            <v-card-text class="text-center">
                <div class="my-2">
                    There was a problem submitting the form:
                </div>
                <div class="my-2">
                    {{ (data.error).toUpperCase() }}
                </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="data.isError = !data.isError">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-container>
</template>

<script setup>
import {reactive, ref} from 'vue';
import { useTradeShowFormStore } from '../stores/tradeShowForm';
//import salesRep store
import {useSalesRepStore} from '../stores/salesRep';

//import form validators
import {validationRules} from '../utils/validators';

//import useDisplay to access current screensize for custom breakpoints
import {useDisplay} from 'vuetify';

//import  to call cloud functions
import { useInvokeCF } from '../composables/invokeCF.js';

//construct createReRoofLead cloud function object
const createReRoofLead = useInvokeCF('createReRoofLeadCF');

const { mdAndUp } = useDisplay();

//define tradeshow store
const tradeShowFormStore = useTradeShowFormStore();

//define salesRep store
const salesRepStore = useSalesRepStore();

//values for picklists
const serviceTypes = [
    'Commercial',
    'Duradek',
    'EPDM Roofing',
    'Gutters',
    'New Construction',
    'Roof Replacement',
    'Roof Repair',
    'Siding',
    'TPO Roofing',
    'Windows',
    'Other'
]

const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

const salesReps = salesRepStore.getSalesReps;

//ref to hold entire form element for validation
const tradeShowForm = ref(null);

//reactive state data of component
const data = reactive({
    copyAddress: false,
    selectedOwner: null,
    fetchState: 'standby',
    isSending: false,
    isSuccess: false,
    isError: false,
    response: null,
    error: null,
});


//function that returns default values for the form    
const defaultValues = () => ({
    Service_Type_Select_All_That_Apply: null,
    First_Name: null,
    Last_Name: null,
    Phone: null,
    Text_Message_Opt_Out: false,
    Email: null,
    Street: null,
    City: null,
    State: null,
    Zip_Code: null,
    Job_Site_Street: null,
    Job_Site_City: null,
    Job_Site_State: null,
    Job_Site_Zip_Code: null,
    Storm_Damage_Suspected: null,
    Description: null,
})

//reactive state data of Form
//use API names of the corresponding fields in a Zoho lead record
const formData = reactive(defaultValues());

//copies values of contact address fields to Service Address fields when triggered
const copyAddressHandler = () => {

    if (!data.copyAddress) {
        formData.Job_Site_Street = formData.Street;
        formData.Job_Site_City = formData.City;
        formData.Job_Site_State = formData.State;
        formData.Job_Site_Zip_Code = formData.Zip_Code;
    }
    
}

const submitHandler = async () => {

    //validate form
    const { valid } = await tradeShowForm.value.validate();

    //if form passes validation, continue
    if (valid) {
        const payload = { ...formData };

        //Append Owner and Layout properties to payload
        payload.Owner = getOwnerData();
        payload.Layout = {
            name: "ReRoof Sales",
            id: "4331172000000091055"
        }

        //append zoho-specific properties to payload
        payload.Webform_Source = tradeShowFormStore.getTradeShowName;
        payload.Market = tradeShowFormStore.getTradeShowMarket;
        payload.Lead_Source = "Trade Show";
        payload.Company = `${formData.First_Name} ${formData.Last_Name}`;

        //use createReRoofLead CF object to invoke cloud function
        // and save response to reactive state
        data.fetchState = 'pending'
        data.isSending = true;
        data.response = await createReRoofLead.invokeCF(payload);

        if (data.response.data.data.length > 0) {

            const zohoResponse = data.response.data.data[0];
            data.isSending = false;

            if (zohoResponse.code === 'SUCCESS') {
                data.fetchState = 'resolved';
                data.isSuccess = true;
                clearForm();
            } else {
                data.fetchState = 'rejected';
                data.isError = true;
                data.error = zohoResponse.message
                console.log('Error Response from Zoho: ',zohoResponse);
            }

        } else {
            data.fetchState = 'rejected';
            data.isError = true;
            data.error = zohoResponse.message
            console.log('Error Response from Zoho: ',zohoResponse);
        }

    }


};

const getOwnerData = () => {

    //filter through array of salesReps to find rep with ID matching the selectedOwner
    const currentOwner = salesReps.filter( (salesRep) => {

        if ( salesRep.id === data.selectedOwner ) {
            return true;
        } else {
            return false;
        }
    } )



    //if there is a match, return the first index of the currentOwner array
    if (currentOwner.length > 0) {
        return currentOwner[0];

    //else return admin as default owner
    } else {
        return {
            id: "4331172000000246013",
            name: "Zoho Admin"
        };
    }
    
}

//replaces the formData with a fresh set of default values, clearing the form
//uses the .assign() method to replace the values of formData with the values from the object returned by defaultValues()
const clearForm = () => {

    data.selectedOwner = null;
    data.copyAddress = false;
    return Object.assign(formData, defaultValues());

};

const goBack = () => {
    clearForm();
    tradeShowFormStore.clearTradeShow();
}




</script>