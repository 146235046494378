// creates a store that stores data about trade shows pulled from the firestore db
import { defineStore } from 'pinia';
import { getTradeShowsDb } from '../firebase/tradeShowDb';

export const useTradeShowFormStore = defineStore({
    id: 'tradeShow',
    state: () => ({
        selectedTradeShow: null,
        selected: false,
        tradeShows: null,
        fetchState: 'standby',
        error: null,
    }),
    getters: {
        getTradeShowName: (state) => {
            if (state.selectedTradeShow !== null) {
                return state.selectedTradeShow.webformSource
            } else {
                return null;
            }
        },
        getTradeShowMarket: (state) => {
            if (state.selectedTradeShow !== null) {
                return state.selectedTradeShow.market
            } else {
                return null;
            }
        }
    },
    actions: {
        setTradeShow(tradeShow) {
            this.selected = true;
            this.selectedTradeShow = tradeShow;
        },
        
        clearTradeShow() {
            this.selected = false;
            this.selectedTradeShow = null;
        },

        async getTradeShows() {
            this.fetchState = 'pending'
            try {
                this.tradeShows =  await getTradeShowsDb();
                this.fetchState = 'resolved';
            } catch (err) {
                console.log("Error in getTradeShows in tradeShowFormStore: ",err);
                this.error = err;
                this.fetchState = 'rejected';
            }
            
        }
    }
})