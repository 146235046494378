//reusable composable function that invokes the specified firebase cloud function

import { auth, functions } from '../firebase/index';
import { httpsCallable } from '@firebase/functions';
import {reactive} from 'vue';

//TODO: add auth check
//TODO: remove console logs

//cfName should be string name of cloud function from functions/index.js
//data is the data param passed into the cloud function
export function useInvokeCF(cfName) {
    const result = reactive({

        fetchState: 'standby',
        data: null,
        invokeCF: async (data) => {

            console.log('InvokeCF called with this data:', data);

            const cloudFunction = httpsCallable(functions, cfName);
        
            try {

                result.fetchState = 'pending';
                //reset data to null
                result.data = null;
                const response = await cloudFunction(data);
                result.data = response;
                result.fetchState = 'resolved';
                return response;

            } catch (error) {

                result.data = error;
                result.fetchState = 'rejected';
                console.log(`Error calling ${cfName} from browser`);
                console.log('Error:', error);

            }
        
        }
    })

    return result;
}

