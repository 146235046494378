<!-- Show hardware request form only if data about offices 
from the officeStore has been fetched from the db -->

<template>
    <Loading v-if="data.fetchState !== 'resolved' && data.fetchState !== 'rejected'"></Loading>

    <!-- Error modal -->
    <v-dialog v-model="data.isError" :scrim="false" persistent class="w-50" min-width="200" max-width="400">
        <v-card color="error">
            <v-card-text class="text-center">
                <div class="my-2">
                    <p>Something went wrong. Please try again.</p>
                    <p>If the issue persists, please contact support.</p> 
                </div>
                <div class="my-2">
                    <p v-if="data.isFetchError">The record could not be fetched from Zoho. <br> Please confirm the record ID is correct.</p>
                    <p v-if="data.isSubmitError">There was a problem updating the record in Zoho.</p>
                </div>
                <div class="my-2" v-if="data.error">
                    {{ (data.error).toUpperCase() }}
                </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <!-- redirect back to home page -->
                <v-btn @click="() => { router.push({ path: '/' }) }">Go Back</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Sending Form modal -->
    <v-dialog
        v-model="data.isSending"
        :scrim="false"
        persistent
        class="w-50"
        min-width="200"
        max-width="400"
    >
        <v-card color="primary" class="pa-5">
            <v-card-text>
                Submitting
                <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        >
                        </v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- Success modal -->
    <v-dialog
                v-model="data.isSuccess"
                :scrim="false"
                persistent
                class="w-50"
                min-width="200"
                max-width="400"
            >
                <v-card color="success" class="pa-5">
                    <v-card-text class="text-center">
                        Successfully Submitted!
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn @click="() => { router.push({ path: '/' }) }">Ok</v-btn>
                    </v-card-actions>
                </v-card>
    </v-dialog>


    <!-- Share message with employee modal -->
    <v-dialog
                v-model="data.isConfirm"
                :scrim="false"
                class="w-50"
                min-width="200"
                max-width="400"
            >
                <v-card class="pa-5">
                    <v-card-text class="text-center">
                        Do you want to share the approval status with the employee?
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn
                            variant="elevated" 
                            color="success"
                            @click="() => {
                            data.isConfirm = !data.isConfirm;
                            formData.Share_Message_With_Employee = true;
                            submitHandler();
                            }"
                        >Yes</v-btn>

                        <v-btn
                            variant="elevated"
                            color="primary"
                            @click="() => {
                            data.isConfirm = !data.isConfirm;
                            formData.Share_Message_With_Employee = false;
                            submitHandler();
                            }"
                        >No</v-btn>
                    </v-card-actions>
                </v-card>
    </v-dialog>

    <v-container v-if="data.fetchState === 'resolved'">
        <v-row class="d-flex justify-center">
            <v-col cols="12" md="6">

                <!-- Title Section -->

                <v-container>
                    <div class="text-center text-h4 mt-4">ALPHA OMEGA</div>
                    <div class="text-center text-h4">HARDWARE REQUEST</div>
                    <div class="divider"></div>
                </v-container>

                <!-- Item Requested Card -->

                <v-card class="pa-5 my-2">
                    <v-card-title class="mb-2">Device Requested</v-card-title>
                    <v-card-text  class="text-body-1">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center">
                                <!-- v-bind icon prop to the deviceIcon computed value -->
                                <v-icon :icon="deviceIcon" color="primary" size="150"></v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <b>Device:</b> {{ data.response.Make }} {{ data.response.Model }}
                            </v-col>
                            <v-col cols="12" md="6">
                                <b>Type of Device:</b> {{ data.response.Hardware_Type }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <b>Estimated Cost:</b> ${{ data.response.Estimated_Cost }}
                            </v-col>
                            <v-col cols="12" md="6">
                                <b>Cost Code:</b> {{ data.response.Cost_Code }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <b>Vendor:</b> {{ data.response.Vendor }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <!-- Requested For Card -->

                <v-card class="pa-5 my-2">
                    <v-card-title class="mb-2">Requested For</v-card-title>
                    <v-card-text class="text-body-1">
                        <v-row>
                            <v-col cols="12">
                                <p class="font-weight-bold">{{ data.response.Requested_For }}</p>
                                <p>
                                    <a :href="`mailto:${data.response.Requestor_Email}`"
                                        class="text-decoration-underline text-primary">{{
                                            data.response.Requestor_Email }}</a>
                                </p>
                                <p>{{ data.response.Office }}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <!-- Reason for Request Card -->

                <v-card class="pa-5 my-2">
                    <v-card-title class="mb-2">Reason for Request</v-card-title>
                    
                    <v-card-text class="text-body-1">
                        <v-row>
                            <v-col cols="12">
                                {{ data.response.Reason }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <!-- Requested by card -->

                <v-card class="pa-5 my-2">
                    <v-card-title class="mb-2">Requested By</v-card-title>
                    <v-card-text class="text-body-1">
                        <v-row>
                            <v-col cols="12" md="6">
                                <p class="font-weight-bold">{{ data.response.Requested_By }}</p>
                                <p>
                                    <a :href="`mailto:${data.response.Requestor_Email}`"
                                        class="text-decoration-underline text-primary">{{
                                            data.response.Requestor_Email }}</a>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>


                <!-- Approval Section Card -->

                <v-card class="pa-5 my-2">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-form ref="approvalForm">
                                    <v-textarea
                                        label="Add Approval/Denial Comments Here"
                                        variant="solo"
                                        v-model="formData.Approval_Comments"
                                        required
                                        :rules="validationRules.notEmpty"
                                    ></v-textarea>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                            <div>
                                <v-btn 
                                color="success"
                                variant="elevated"
                                @click="approveHandler"
                                >
                                Approve
                                </v-btn>
                                <v-btn 
                                color="primary"
                                variant="elevated"
                                @click="denyHandler"
                                >
                                Deny
                                </v-btn>
                            </div>
                        </v-card-actions>
                </v-card>

            </v-col>
        </v-row>

    </v-container>
</template>

<script setup>

import { FUNCTIONHOST } from '../firebase/index.js';
import { onMounted } from 'vue';
import { reactive, computed, ref } from 'vue';
import Loading from '../components/Loading.vue';

//import validation rules for form
import { validationRules } from '../utils/validators.js';

//import useRouter to allow for redirection to another page
import { useRouter } from 'vue-router';

//import useRoute to gain access to route params
import { useRoute } from 'vue-router';

//import userStore to access user data
import { useUserStore } from '../stores/user.js';

const userStore = useUserStore();

//access router object
const router = useRouter();

//access route and route params
const route = useRoute();

const data = reactive({
    fetchState: 'standby',
    response: null,
    record: null,
    isError: false,
    isSending: false,
    error: null,
    isSuccess: false,
    isConfirm: false,
});

const formData = reactive({
    Approval_Comments: '',
    approved: false,
    Share_Message_With_Employee: false,
});

const approvalForm = ref(null);

//changes the type of icon displayed based on the type of hardware requested
const deviceIcon = computed(() => {
    if (data.response) {
        if (data.response.Hardware_Type === 'Laptop') {
            return 'mdi-laptop';
        } else if (data.response.Hardware_Type === 'PC') {
            return 'mdi-desktop-classic';
        } else if (data.response.Hardware_Type === 'Monitor') {
            return 'mdi-monitor';
        } else if (data.response.Hardware_Type === 'Printer') {
            return 'mdi-printer';
        } else if (data.response.Hardware_Type === 'Cell Phone') {
            return 'mdi-cellphone-sound';
        } else if (data.response.Hardware_Type === 'Tablet') {
            return 'mdi-tablet';
        } else if (data.response.Hardware_Type === 'Laptop Dock') {
            return 'mdi-dock-bottom';
        } else {
            return 'mdi-devices';
        }
    }
    return 'mdi-devices';
});

// if route params are present in the address bar when component is created
// fetch record from Zoho
onMounted(() => {
    if (route.params.id) {
        handleFetchRecord();
    }
});

const handleFetchRecord = async () => {
    // set fetch state to pending
    data.fetchState = 'pending';

    try {
        // construct fetch call using the FUNCTIONHOST and route params, and the user's firebase access token
        // as the authorization header
        const response = await fetch(`${FUNCTIONHOST}/zohoApi/getHardwareRequest/${route.params.id}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                authorization: `Bearer ${userStore.data.accessToken}`
            }
        });

        //parse response
        const parsedResponse = await response.json();
        data.response = parsedResponse.data[0];
        console.log(data.response);

        //set fetchstate to resolved
        data.fetchState = 'resolved';

        //handle errors and trigger error modal
    } catch (err) {
        data.fetchState = 'rejected';
        data.isError = true;
        data.isFetchError= true;
        data.error = `${err}`;
        console.log(err);
    }

}

//function to execute when user clicks approve button
const approveHandler = async () => {
    //validate form
    const { valid } = await approvalForm.value.validate();

    //set form data to approved and submit form
    if (valid) {
        formData.approved = true;
        data.isConfirm = true;
    }
}

//function to execute when user clicks deny button
const denyHandler = async () => {
    //validate form
    const { valid } = await approvalForm.value.validate();

    //set form data to denied and submit form
    if (valid) {
        formData.approved = false;
        data.isConfirm = true;
    }

}

//function to validate and submit form
const submitHandler = async () => {

    //validate form
    const { valid } = await approvalForm.value.validate();

    //if form passes validation continue
    if (valid) {
        const payload = { ...formData };

        //append id of zoho record to payload
        payload.id = route.params.id;

        //set zoho record status based on approval status
        if (formData.approved) {
            payload.Request_Status = 'Approved';
        } else {
            payload.Request_Status = 'Denied';
        }

        //set fetch state to pending
        data.fetchState = 'pending';
        data.isSending = true;

        try {
            const response = await fetch(`${FUNCTIONHOST}/zohoApi/updateHardwareRequest/${route.params.id}`, {
            method: 'PUT',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${userStore.data.accessToken}`
            },
            body: JSON.stringify(payload)
        });

        //parse response
        const zohoResponse = await response.json();
        // console.log(zohoResponse);

        //if Zoho response contains a success code, show success modal
        if (zohoResponse.data[0].code === 'SUCCESS') {
            
            data.isSending = false;
            data.fetchState = 'resolved';
            data.isSuccess = true;
        //if Zoho response contains an error code show error modal
        } else {
            data.fetchState = 'rejected';
            data.isError = true;
            data.isSubmitError = true;
            data.error = zohoResponse.data[0].message;
            console.log('Error response from Zoho: ', zohoResponse);
        }

        } catch (err) {
            data.fetchState = 'rejected';
            data.isError = true;
            data.isSubmitError = true;
            data.error = `${err}`;
            console.log(err);
        }
    }
};

</script>