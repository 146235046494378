<!-- Show hardware request form only if data about offices 
from the officeStore has been fetched from the db -->

<template>
    
    <Loading v-if="officeStore.fetchState !== 'resolved'"></Loading>
    <div v-if="officeStore.fetchState === 'resolved'">
        <HardwareRequestForm/>
    </div>

</template>

<script setup>

    import Loading from '../components/Loading.vue'
    import HardwareRequestForm from '../components/HardwareRequestForm.vue';
    import { useOfficeStore } from '../stores/office';
    import { onMounted } from 'vue'

    const officeStore = useOfficeStore();

    onMounted(() => {
        officeStore.getOffices();
    })

</script>

