import { defineStore } from "pinia";
import { getOfficesDb } from "../firebase/officeDb";

export const useOfficeStore = defineStore({
    id: 'office',
    state: () => ({
        selected: false,
        selectedOffice: null,
        offices: null,
        fetchState: 'standby',
        error: null,
    }),
    getters: {
        getOfficeName: (state) => {
            if (state.selectedOffice) {
                return state.selectedOffice.name
            } else {
                return null;
            }
        },

        getOfficeData: (state) => {
            if (state.selectedOffice) {
                return state.selectedOffice
            } else {
                return null;
            }
        }
    },
    actions: {

        async getOffices() {
            this.fetchState = 'pending'
            try {
                this.offices =  await getOfficesDb();
                this.fetchState = 'resolved';
            } catch (err) {
                console.log("Error in getOffices in officeStore: ",err);
                this.error = err;
                this.fetchState = 'rejected';
            }
        },

        setOffice(office) {
            this.selected = true;
            this.selectedOffice = office;
        },

        clearOffice() {
            this.selected = false;
            this.selectedOffice = null;
        }
    }

})