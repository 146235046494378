<template>

<v-row class="d-flex justify-center">
  <v-col cols="12" md="6">
    <v-container>
    <div class="text-center text-h4 mt-4">ALPHA OMEGA</div>
    <div class="text-center text-h4">SOLAR ESTIMATE</div>
    <div class="divider"></div>
  </v-container>

  <!-- <v-container v-if="solarEstimateStore.fetchState === 'pending'">
    <Loading />
  </v-container> -->

  <SolarEstimate v-if="solarEstimateStore.showEstimate === true"/>

  <SolarEstimateForm v-if="solarEstimateStore.showForm === true || solarEstimateStore.showMargins === true" />
  <SolarActions />
  </v-col>
</v-row>
  
    
</template>

<script setup>
    import SolarEstimateForm from '../components/SolarEstimateForm.vue';
    import SolarActions from '../components/SolarActions.vue';
    import Loading from '../components/Loading.vue';
    import SolarEstimate from '../components/SolarEstimate.vue';

    import { useSolarEstimateStore } from '../stores/solarEstimate';

    const solarEstimateStore = useSolarEstimateStore();
</script>
