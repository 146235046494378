import {createRouter, createWebHistory} from 'vue-router';

//import vuefire functionality
import { getCurrentUser } from 'vuefire';

//import userStore functionality
import { useUserStore } from '../stores/user';


//components for router paths
import GoogleLogin from '../views/GoogleLogin.vue';
import Home from '../views/Home.vue';
import TradeShow from '../views/TradeShow.vue';
import Unauthorized from '../views/Unauthorized.vue';
import Solar from '../views/Solar.vue';
import HardwareRequests from '../views/HardwareRequests.vue';
import HardwareApproval from '../views/HardwareApproval.vue';


const routes = [
    {
        path: '/login',
        name: 'login',
        component: GoogleLogin,
    },
    {
        path: '/hardware-requests',
        name: 'Hardware Requests',
        component: HardwareRequests,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresManager: true,
        }
    },
    {
        path: '/hardware-approval/:id',
        name: 'Hardware Approval',
        component: HardwareApproval,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresManager: true,
        }
    },
    {
        path: '/tradeshows',
        name: 'Trade Shows',
        component: TradeShow,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresManager: false,
        }
    },
    {
        path: '/solar',
        name: 'solar',
        component: Solar,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresManager: false
        }
    },
    {
        path: '/403',
        name: 'Unauthorized',
        component: Unauthorized,
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
            requiresManager: false
        }
    },
    
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

//navigation guard to protect unauthorized access to particular pages
router.beforeEach(async (to) => {
    //get access to user store
    const userStore = useUserStore();

    //this blocks the router guard until the userStore has finished fetching the user data from BOTH the firebase Auth
    //and the firestore database
    //Otherwise user would be redirected to the 403 page before the userStore has finished fetching the user data
    await userStore.initialPromise;

    // routes with `meta: { requiresAuth: true }` will check for the users, others won't
    if (to.meta.requiresAuth) {
      const currentUser = await getCurrentUser()
      // if the user is not logged in, redirect to the login page
      if (!currentUser) {
        return {
          path: '/login',
          query: {
            // we keep the current path in the query so we can redirect to it after login
            // with `router.push(route.query.redirect || '/')`
            redirect: to.fullPath,
          },
        }
      }
    }

    // blocks users without Admin role from accessing routes with `meta: { requiresAdmin: true }`
    if (to.meta.requiresAdmin) {
        const userProfile = userStore.profile;

        if (!userProfile || !userProfile.admin) {
            return {
                path: '/403'
            }
        }
    }

    // blocks users without Manager role from accessing routes with `meta: { requiresManager: true }`
    if (to.meta.requiresManager) {
        const userProfile = userStore.profile;

        if (!userProfile || !userProfile.manager) {
            return {
                path: '/403'
            }
        }
    }
  })

export default router;