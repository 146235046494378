<template>
    <v-container>
        <v-card class="pa-5">
            <v-card-actions class="d-flex justify-center">

                <v-btn 
                    v-if="solarEstimateStore.showMargins === false && solarEstimateStore.showEstimate === false"
                    color="success" 
                    variant="elevated"
                    @click="nextBtnHandler"
                    :disabled="loadingState.nextBtn"
                    :loading="loadingState.nextBtn"
                >Next</v-btn>

                <v-btn
                    v-if="solarEstimateStore.showMargins === true" 
                    color="success" 
                    variant="elevated"
                    @click="calculateHandler"
                    :disabled="loadingState.calculateBtn"
                    :loading="loadingState.calculateBtn"
                >Calculate</v-btn>

                <!-- Return to Margins Back Button -->
                <v-btn
                    v-if="solarEstimateStore.showEstimate === true" 
                    color="warning" 
                    variant="elevated"
                    @click="goBackToMarginsHandler"
                >Back</v-btn>

                <!-- Return to Input Form Back Button -->
                <v-btn
                    v-if="solarEstimateStore.showMargins === true" 
                    color="warning" 
                    variant="elevated"
                    @click="goBackToInputsHandler"
                >Back</v-btn>

                <v-btn 
                    color="primary"
                    variant="elevated"
                    @click="resetHandler"
                >Reset</v-btn>

            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script setup>
import { reactive } from 'vue';
import { useSolarEstimateStore } from '../stores/solarEstimate';
const solarEstimateStore = useSolarEstimateStore();

// Loading animation state for buttons
// shows loading animation on button when true
const loadingState = reactive({
    nextBtn: false,
    calculateBtn: false,
})


// HANDLERS FOR BUTTONS ------------------------------------------------------

const calculateHandler = async () => {
    //trigger loading animation on button
    loadingState.calculateBtn = true;

    const input = solarEstimateStore.getCurrentInput;

    await solarEstimateStore.calculateEstimate(input);

    solarEstimateStore.showEstimateHandler();

    //cancel loading animation on button
    loadingState.calculateBtn = false;
}


const nextBtnHandler = async () => {
    //trigger loading animation on button
    loadingState.nextBtn = true;

    const input = solarEstimateStore.getCurrentInput;

    await solarEstimateStore.calculateEstimate(input);

    solarEstimateStore.goBackToMarginsHandler();

    //cancel loading animation on button
    loadingState.nextBtn = false;
}

const resetHandler = () => {
    solarEstimateStore.resetToDefault();
}

const goBackToMarginsHandler = () => {
    solarEstimateStore.goBackToMarginsHandler();
}

const goBackToInputsHandler = () => {
    solarEstimateStore.goBackToInputsHandler();
}

</script>