import { createApp } from 'vue'
import {VueFire, VueFireAuth} from 'vuefire'
import {firebaseApp} from './firebase'
import './style.css'
import App from './App.vue'

//routing
import router from './routes'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//import MDI icon set
import '@mdi/font/css/materialdesignicons.css'
import {aliases, mdi} from 'vuetify/iconsets/mdi'

//import aocgTheme
import aocgTheme from './themes/aocgTheme'

// Pinia state management
import { createPinia } from 'pinia'

const pinia = createPinia();

const vuetify = createVuetify({
  components,
  directives,
  //add MDI icons
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  },
  //load AOCG theme used in PO Invoice Ticketing App
  theme: {
    defaultTheme: 'aocgTheme',
    themes: {
      aocgTheme: aocgTheme
    }
  }
})

createApp(App)
.use(VueFire, {
  firebaseApp,
  modules: [
    VueFireAuth(),
  ],
})
.use(pinia)
.use(router)
.use(vuetify)
.mount('#app')
