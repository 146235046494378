<template>
    <v-container id="trade-show-button-container">
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card id="trade-show-button-card" class="pa-5">
                    <v-card-title class="text-center text-h5">Choose A Trade Show</v-card-title>
                    <v-card-text class="d-flex justify-center flex-wrap">
                        <v-btn
                            size="x-large" 
                            block 
                            color="primary" 
                            v-for="show in tradeShowFormStore.tradeShows"
                            class="ma-3 wrap-text tradeshow-btn"
                            @click="() => selectionHandler(show)"
                        ><div>
                            {{ show.webformSource }}
                        </div></v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    
</template>

<script setup>
import { useTradeShowFormStore } from '../stores/tradeShowForm';

const tradeShowFormStore = useTradeShowFormStore();

const selectionHandler = (show) => {
    tradeShowFormStore.setTradeShow(show);
     
}

</script>

<style>
  .v-btn.wrap-text>.v-btn__content{
    white-space:normal;
  }
  
  .tradeshow-btn {
		min-height: 100px;
  }
</style>
